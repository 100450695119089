import { getBaseUrl } from '@/utils/functions'

// General
export var APP_BASE_URL = process.env.NODE_ENV == 'development' ? 'http://era.dev.local' : getBaseUrl()

// DB Api
export const DATABASE_API_KEY = 'b47e8c19-9e29-4287-acb1-4f34481402a0'
export var DATABASE_API_BASE_URL = APP_BASE_URL + '/server-1/db-api/'

// Storage Api
export var STORAGE_API_BASE_URL = APP_BASE_URL + '/server-1/sto-api/'